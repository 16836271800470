import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventDetails } from "../actions/eventActions";

export function useEventDetails ({ eventId}) {
    const dispatch = useDispatch();

    const eventDetails = useSelector(state => state.eventDetails);
    const { loading, error, event } = eventDetails;

    useEffect(() => {
        dispatch(getEventDetails(eventId));
    }, [dispatch, eventId]);

    return { loading, error, event };
}