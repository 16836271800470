import React, { useLayoutEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

const PrivacyPolicyScreen = () => {
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <section className="information" id="top">
      <Container fluid="lg">
        <Row className="information__header">
          <h1 className="information__header--title">Política de Privacidad</h1>
        </Row>
        <Row className="information__content">
          <Col className="information__content--item">
            <p className="information__content--text mb-1">
            La privacidad de las personas usuarias es prioritaria para MUJER SIN REGLAS. Nos preocupamos de que tu experiencia sea 100% segura.
            </p>
            <p className="information__content--text mb-1">
            Tu confianza y privacidad son lo más importante para nosotras, por eso, te explicamos a continuación, de forma sencilla y transparente qué hacemos exactamente con tu información personal.
            </p>
            <p className="information__content--text mb-1">
            Si te queda alguna duda o quieres más información a este respecto, escríbenos a carolina.mujersinreglas@gmail.com y te ayudaremos.
            </p>
          </Col>
          <Accordion className="information__accordion">
            <Accordion.Item eventKey="0" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                Responsable del tratamiento
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                La responsable del tratamiento de tus datos personales es Mujer Sin Reglas, representada por Carolina Ackermann Barreiro, con domicilio en Calle Cervantes, 08860, Castelldefels, Barcelona, y con DNI 45793377D. Para cualquier consulta sobre tus datos, puedes contactarnos en: carolina.mujersinreglas@gmail.com.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                ¿Qué datos tuyos recogemos?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Los datos personales que recogemos pueden incluir: nombre, dirección de correo electrónico, y cualquier otra información proporcionada voluntariamente a través de nuestros formularios de contacto, descarga de documentos, o suscripción a newsletter.
                </li>              
                <p className="information__content--text mb-1">
                La base legal para el tratamiento de tus datos es tu consentimiento, que nos proporcionas de manera expresa al enviar tus datos a través de nuestros formularios. 
                </p>               
                <p className="information__content--text mb-1">
                Además, nos basamos en nuestro interés legítimo para gestionar consultas y mejorar nuestros servicios.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                Recogida de datos para la contratación de charlas o talleres
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Utilizamos formularios de Google para gestionar la solicitud o inscripción de charlas, talleres o eventos. A través de estos formularios, recogemos información personal necesaria para la organización y gestión. Los datos recabados pueden incluir nombre, correo electrónico, teléfono y otra información necesaria para el evento solicitado.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  Newsletter, email y descarga de documentos
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <li className="information__content--text information__content--list ps-3 mb-1">
                En caso de que lo solicites expresamente desde la web <a className='information__content--link' href="https://www.mujersinreglas.info" target="_blank" rel="noopener noreferrer">www.mujersinreglas.info</a>, puedes recibir un correo nuestro para la descarga de documentos de tu interés como: "Atención sanitaria integral y de calidad en la menopausia y climaterio" y otros que el proyecto MSR ofrezca para libre descarga a través de confirmación previa de tu correo electrónico y el deseo expreso de recibir dicho documento.
                </li>
                <p className="information__content--text mb-1">
                La base legal que nos permite llevar a cabo este tratamiento de tus datos y el envío de documentos es tu consentimiento, que nos prestas de forma expresa al hacer tu solicitud de descarga de documentos.
                </p>
                <li className="information__content--text information__content--list ps-3 mb-1">
                En el caso de que lo solicites expresamente, recibirás nuestros correos electrónicos con información sobre charlas, seminarios y jornadas que organizamos desde el proyecto de Mujer Sin Reglas; así como de otros eventos y acciones que pueden interesarte.
                </li>

                <p className="information__content--text mb-1">
                La base legal que nos permite llevar a cabo este tratamiento de tus datos y el envío de la newsletter es tu consentimiento, que nos prestas de forma expresa al rellenar el formulario <span className="italic">"Me sumo"</span> de la plataforma de marketing Brevo. Los términos de usos de dicha plataforma los encuentras aquí: <a className='information__content--link' href="https://www.brevo.com/es/legal/termsofuse/" target="_blank" rel="noopener noreferrer">https://www.brevo.com/es/legal/termsofuse/</a>
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  Duración del tratamiento
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Tus datos personales serán tratados mientras dure la relación y hasta que solicites su supresión. En el caso de la suscripción a la newsletter, conservaremos tus datos hasta que decidas darte de baja.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  ¿A quién comunicamos tus datos?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Mujer Sin Reglas necesita el apoyo de terceros para ofrecer adecuadamente sus servicios, con los cuales celebra los debidos acuerdos de confidencialidad y verifica el cumplimiento de las normativas sobre protección de datos personales.
                </p>
                <p className="information__content--text mb-1">
                Los datos suministrados a estos terceros no podrán ser utilizados para otros fines no autorizados por el titular de los datos.
                </p>
                <p className="information__content--text mb-1">
                En cumplimiento de los principios de información y transparencia, se hace saber que estos terceros son:
                </p>
                <p className="information__content--text mb-1">
                  <span className="bold">Google:</span> Herramienta utilizada para el posicionamiento web y envío de correo electrónico. Este servicio está a cargo de la empresa Google LLC, ubicada en Mountain View, California, Estados Unidos de América. Google LLC cumple con las cláusulas contractuales tipo aprobadas por la Comisión Europea para la transferencia internacional de datos. Para más información sobre su política de privacidad, consulta: <a className='information__content--link' href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>.
                </p>
                <p className="information__content--text mb-1">
                  <span className="bold">Google Forms: </span>: Es un servicio proporcionado por Google LLC, y el tratamiento de los datos recogidos a través de este servicio está sujeto a la política de privacidad de Google, que puedes consultar aquí: <a className='information__content--link' href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a>.
                </p>
                <p className="information__content--text mb-1">
                  <span className="bold">Brevo</span>: Plataforma de marketing para el envío de newsletter que cuenta con la certificación ISO 27001: 2013, uno de los estándares internacionales de seguridad de la información más reconocidos. La certificación de Brevo (ex Sendinblue) demuestra nuestro compromiso de mantener un marco de seguridad de datos integral y de varios niveles. Política de privacidad Protección de datos personales: <a className='information__content--link' href="https://www.brevo.com/es/legal/termsofuse/" target="_blank" rel="noopener noreferrer">https://www.brevo.com/es/legal/termsofuse/</a>
                </p>
                <p className="information__content--text mb-1">
                  <span className="bold">MongoDB:</span> Utilizamos MongoDB como base de datos para el almacenamiento y gestión segura de la información personal que recabamos. MongoDB Inc., con sede en Nueva York, EE.UU., proporciona servicios de base de datos en la nube bajo un alto estándar de seguridad. MongoDB cumple con las cláusulas contractuales tipo aprobadas por la Comisión Europea para la transferencia internacional de datos y ofrece protección de datos mediante cifrado y otras medidas de seguridad avanzadas. Puedes consultar su política de privacidad aquí: <a className='information__content--link' href="https://www.mongodb.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.mongodb.com/legal/privacy-policy</a>.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  ¿Cuáles son tus derechos cuando nos facilitas tus datos
                  personales?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Como titular de tu información personal, tienes derecho a:
                </p>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Acceder a los datos que tratamos sobre ti y a información detallada sobre el tratamiento que hacemos.
                </li>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Puedes solicitar que se rectifique tu información si consideras que es inexacta.
                </li>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Puedes solicitar que tus datos sean suprimidos, oponerte a que los usemos y limitar el tratamiento que hacemos de ellos.
                </li>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Tienes derecho a la portabilidad de tus datos, es decir, a recibir tus datos o que se los entreguemos a un tercero en un formato que permita que este los trate.
                </li>
                <li className="information__content--text information__content--list ps-3 mb-1">
                Por supuesto, si consideras que no hemos atendido correctamente tus derechos o quieres más información al respecto, puedes pedirla a la Agencia Española de Protección (<a className='information__content--link' href="https://www.aepd.es" target="_blank" rel="noopener noreferrer">www.aepd.es</a>).
                </li>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  ¿Cómo he obtenido tus datos?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Los datos personales que trato en <a className='information__content--link' href="https://www.mujersinreglas.info" target="_blank" rel="noopener noreferrer">www.mujersinreglas.info</a> proceden de los formularios que se encuentran en esta web y que has cumplimentado voluntariamente y aceptado.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" flush>
              <Accordion.Header>
                <h4 className="information__content--title">
                  ¿Dudas? ¿Problemas? ¿Más información?
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p className="information__content--text mb-1">
                Escríbenos a carolina.mujersinreglas@gmail.com y te ayudamos.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
    </section>
  );
};

export default PrivacyPolicyScreen;
